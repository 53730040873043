@import '~antd/dist/antd.css';

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border: none;
}

.ant-list-header,
.ant-list-footer {
  padding-top: 0px !important;
  padding-bottom: 5px !important;
}

.ant-message {
  position: absolute;
  bottom: 20px !important;
  top: unset !important;
}
