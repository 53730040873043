.antd-ol-control {
  position: absolute;
}

.ant-ol-renderer-container {
  width: inherit;
}

@import './popup/style.css';
@import './sider/style.css';
