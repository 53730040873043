:root {
  --ol-button-background-color: #1890ff;
  --ol-button-background-color-hover: #096dd9;
}

/* WIDGET */
.widget-wrapper {
  text-align: center;
}

.counter-widget-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.counter-widget-size-medium {
  font-size: 6vw;
}

.counter-widget-size-big {
  font-size: 10vw;
}

.counter-widget-size-small {
  font-size: 4.5vw;
}

.circle-loader {
  text-transform: uppercase;
}

/* MAP */
.ol-zoom {
  top: unset;
  left: unset;
  bottom: 3.1em;
  right: 0.5em;
}

.ol-control,
.ol-control:hover {
  background-color: unset;
}

.ol-control button {
  width: 2em;
  height: 2em;
  background-color: var(--ol-button-background-color);
}
.ol-control button:hover,
.ol-control button:focus {
  background-color: var(--ol-button-background-color-hover);
}

.ol-full-screen {
  top: unset;
  bottom: 8em;
}

/* usermenu link mod */
.LinkUserMenuStyle {
  font-family: 'Jost' !important;
  letter-spacing: 0.5pt !important;
}

/* sidebar menu link mod */
.LinkMenuStyle {
  font-family: 'Jost' !important;
  color: rgba(245, 245, 245, 0.644) !important;
  letter-spacing: 1pt !important;
}

.LinkMenuStyle:hover {
  color: white !important;
}

/* cambiamento colore on select e select hover dei link della sidebar */
.ant-menu-item-selected a {
  color: white !important;
}

.ant-menu-item a:hover::before {
  margin-left: 10px !important;
  margin-right: 10px !important;
  border-radius: 6px !important;
  background-color: #f5f5f50f !important;
}

/* Selezione menu sidebar fissa in background */
.ant-menu-item-selected a::after {
  margin-left: 10px !important;
  margin-right: 10px !important;
  border-radius: 6px !important;
  background-color: #f5f5f50f !important;
}

.ant-menu-item-selected a:hover {
  color: white !important ;
}

.ant-layout-sider-light .ant-layout-sider-trigger {
  color: white !important;
  font-size: 20px !important;
}

.MarginIconSidebar {
  margin-top: 20px !important;
}

/* cambiamento icone active da bianche a gialle */
.ant-menu-item-selected span {
  color: #ffbd00 !important;
}
.anticon .ant-menu-item-icon:hover {
  color: #ffbd00 !important;
}

/* Cambiamento colore on hover nei City Details */
.brcolor {
  border-color: none !important;
  transition: all 1s ease;
}
.brcolor:hover {
  border-color: #ffbd00 !important;
}
.stylecitydetails {
  width: 32% !important;
  border-radius: 15px;
  text-align: center;
}
.ant-card-head-title{
  font-size: 20px;
}

/* override modifica tables */
.ant-table-wrapper {
  border: 1px solid;
  border-radius: 6px !important ;
  border-color: transparent !important ;
}

.ant-table-body {
  border-top: 1px solid #00000029;
}

/* fixed tooltip sidebarmenu because sider position is Fixed top */
.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
  width: 75px !important;
}

/* override su sidebar puntini da ellipsis a unset */
.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  padding-left: 27px !important;
  text-overflow: unset !important;
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-submenu
  > .ant-menu-submenu-title {
  text-overflow: unset !important;
}

/* color icon sidebar on hover */
.ant-menu-item:hover span {
  color: #ffbd00 !important;
}

/* override bottoni filtri border radius */
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 6px !important;
}

/* override bottoni task */
.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}

.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

/* override border radius barra di ricerca e tasto cerca su Siti */
.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

/* Star icon filter on City */
.CityStarSwitchTrue {
  height: 6px !important;
}
.CityStarSwitchFalse {
  height: 6px !important;
}

.CityStarSwitchTrue > div.ant-switch-handle {
  left: 15px !important;
  top: -11px !important;
}

.CityStarSwitchFalse > div.ant-switch-handle {
  left: -7px !important;
  top: -11px !important;
}

.CityStarSwitchTrue > div.ant-switch-handle::before {
  content: url('data:image/svg+xml;utf8,<svg version="1.2" baseProfile="tiny" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 47.9 47.9" xml:space="preserve"><path fill="%23F0A601" d="M26.3,2.5l5.4,11c0.4,0.8,1.1,1.3,1.9,1.4l12.1,1.8c2.1,0.3,3,2.9,1.4,4.4l-8.7,8.5 c-0.6,0.6-0.9,1.4-0.7,2.3l2.1,12c0.4,2.1-1.9,3.7-3.7,2.7l-10.8-5.7c-0.8-0.4-1.7-0.4-2.4,0L12,46.6c-1.9,1-4.1-0.6-3.7-2.7l2.1-12 c0.1-0.8-0.1-1.7-0.7-2.3L0.8,21c-1.5-1.5-0.7-4.1,1.4-4.4l12.1-1.8c0.8-0.1,1.6-0.6,1.9-1.4l5.4-11C22.6,0.6,25.3,0.6,26.3,2.5z"/></svg>');
  background-color: #fff0 !important;
  box-shadow: none !important;
  width: 24px;
  height: 26px;
  transition: all 0.3s ease-in-out !important;
  transform: rotate(144deg);
}

.CityStarSwitchFalse > div.ant-switch-handle::before {
  content: url('data:image/svg+xml;utf8,<svg version="1.2" baseProfile="tiny" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  x="0px" y="0px" width="24px" height="24px" viewBox="0 0 47.9 47.9" xml:space="preserve"><g> <path fill="%23FFFFFF" d="M26.2,3.3l5.2,10.5c0.4,0.8,1.1,1.2,1.8,1.3L44.8,17c2,0.3,2.9,2.8,1.3,4.2l-8.3,8.2   c-0.6,0.6-0.9,1.3-0.7,2.2l2,11.5c0.4,2-1.8,3.5-3.5,2.6l-10.4-5.5c-0.8-0.4-1.6-0.4-2.3,0l-10.5,5.5c-1.8,1-3.9-0.6-3.5-2.6   l2-11.5c0.1-0.8-0.1-1.6-0.7-2.2l-8.5-8.2c-1.4-1.4-0.7-3.9,1.3-4.2l11.6-1.7c0.8-0.1,1.5-0.6,1.8-1.3l5.2-10.5   C22.7,1.5,25.2,1.5,26.2,3.3z"/> <path fill="%23292929" d="M23.9,4.5L29,14.8c0.8,1.7,2.4,2.8,4.2,3l11.2,1.7l-8.1,7.9c-1.4,1.4-1.9,3.2-1.5,5l1.9,11l-10-5.3l0,0l0,0   c-0.8-0.4-1.7-0.6-2.6-0.6s-1.8,0.2-2.6,0.7l-10.1,5.3l1.9-11.1v-0.1v-0.1c0.2-1.8-0.4-3.6-1.6-4.8l0,0l0,0l-8.3-8l11.2-1.7   c1.9-0.3,3.5-1.5,4.2-3.1L23.9,4.5 M23.9,1.1c-0.9,0-1.8,0.4-2.3,1.3l-5.4,11c-0.3,0.8-1.1,1.3-1.9,1.4L2.2,16.6   c-2.1,0.3-2.9,2.9-1.4,4.4l8.9,8.6c0.6,0.6,0.8,1.5,0.7,2.3l-2.1,12c-0.3,1.7,1,3,2.5,3c0.4,0,0.8-0.1,1.2-0.3l10.9-5.7   c0.4-0.2,0.8-0.3,1.2-0.3s0.8,0.1,1.2,0.3l10.8,5.7c0.4,0.2,0.8,0.3,1.2,0.3c1.5,0,2.8-1.3,2.5-3l-2.1-12c-0.2-0.9,0.1-1.7,0.7-2.3   l8.7-8.5c1.6-1.5,0.7-4.1-1.4-4.4l-12.1-1.8c-0.8-0.1-1.5-0.6-1.9-1.4l-5.4-11C25.8,1.5,24.8,1.1,23.9,1.1L23.9,1.1z"/></g></svg>');
  background-color: #fff0 !important;
  box-shadow: none !important;
  width: 24px;
  height: 24px;
  transition: all 0.3s ease-in-out !important;
}
/* ----------------------------------------------------------------------------- */

/* override border radius barra di ricerca e tasto cerca su Copertura/coordinate */
.ant-input-affix-wrapper {
  border-bottom-left-radius: 6px !important;
  border-top-left-radius: 6px !important;
}

/* colore nome user */
.modtestologin {
  color: white !important;
}

/* modifica font pagetitle */
.PageTitleSelector {
  font-family: 'Jost' !important;
  letter-spacing: 0.8pt;
  font-size: 20px !important;
  margin-bottom: 0.5em;
  font-weight: 600;
  line-height: 1.4;
  background-color: #fff !important;
  padding: 6px 10px 6px 20px;
  border-radius: 10px;
  border: 1px solid #acacac34;
}

/* override tags border radius su jobs */
.ant-tag {
  border-radius: 6px !important;
}

/* override dropdown notifiche border radius */
.ant-dropdown-menu {
  border-radius: 6px !important;
}

/* override scrollbar */
*::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
  height: 6px;
}
*::-webkit-scrollbar-track-piece {
  background-color: rgb(224, 219, 219);
  border-radius: 20px;
}
*::-webkit-scrollbar-thumb {
  background-color: #ffbd00;
  border-radius: 20px;
}

/* override clear notify button */
.buttonClearNotify {
  margin-top: 5px;
}
/* FOR BUG DELETE VIEW BUTTON MODAL IN DOWNLOAD PAGE */
/* .ant-modal-mask{
  background-color:#a4a4a40a !important;
} */
/* .ant-modal-mask{
  background-color:#a4a4a49e !important;
} */

/* fix una if (quando la pagina va in errore, compare un iframe che blocca tutti gli eventi, lo metto a none  per sbloccarlo) */
iframe {
  display: none;
}

.ant-modal-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* media query with double class in WidgetActions.tsx for Dashboard */
.ActionsDashboardWidgDropdownCSS {
  display: none;
}

@media screen and (max-width: 1600px) {
  .ActionsDashboardWidgButtonCSS {
    display: none;
  }

  .ActionsDashboardWidgDropdownCSS {
    display: block;
  }
}

.upload-center {
  position: fixed;
  right: 32px;
  bottom: 0%;
  z-index: 2147483640;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  }



  .collapse-map.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
    padding: 5px 10px;
    padding-right: 40px;
  }


