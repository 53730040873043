.ol-popup {
  border-radius: 4px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  position: absolute;
  background-color: white;
  padding: 2px;
  bottom: 12px;
  left: -50px;
}
.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 12px;
  left: 48px;
  margin-left: -12px;
}
.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 8px;
  border-radius: 50%;
  display: flex;
  z-index: 3;
}
.ol-popup-closer:hover {
  cursor: pointer;
}
