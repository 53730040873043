.App-logo {
  pointer-events: none;
}

.App-sidebar {
  background-color: rgba(60, 60, 67, 0.03);
}

.App-sidebar * {
  background-color: unset !important;
}

.App-header {
  background-color: #38414a;
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #6c757d !important;
}

.page-container {
  padding: 12px 12px 0 12px;
}
