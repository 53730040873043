:root {
  --map-sider-width: 350px;
  --map-sider-width-reverse: -350px;
  --map-sider-background-color: #ffffff;
}

.sider-control-container {
  width: var(--map-sider-width);
  background-color: var(--map-sider-background-color);
}

.sider-control-container .sider-collapsable {
  position: absolute;
  background-color: inherit;
  width: 24px;
  height: 50px;
  left: 100%;
  top: 0px;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}
.sider-control-container .sider-collapsable:hover {
  cursor: pointer;
}

.map-sider {
  width: inherit;
}

.map-sider .sider-header {
  min-height: 40px;
}

.slide-left {
  animation: slide-left 0.9s cubic-bezier(0.25, 0.46, 0.45, 0.94) 50ms both;
}

.slide-right {
  animation: slide-right 0.9s cubic-bezier(0.25, 0.46, 0.45, 0.94) 50ms both;
}

/**
 * ----------------------------------------
 * animation slide-left
 * ----------------------------------------
 */
@keyframes slide-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(var(--map-sider-width-reverse));
  }
}
/**
 * ----------------------------------------
 * animation slide-right
 * ----------------------------------------
 */

@keyframes slide-right {
  0% {
    transform: translateX(var(--map-sider-width-reverse));
  }
  100% {
    transform: translateX(0);
  }
}

/**
 * ----------------------------------------
 * Footer
 * ----------------------------------------
 */

.info-box-container {
  width: 100%;
}

.info-box-container div {
  width: 100%;
}

/*  Zoom  */
.zoomContainer {
  row-gap: 0px;
  background-color: rgb(24, 144, 255, 0.5);
  text-align: right;
  padding: 5px 10px;
  color: #fff;
  font-size: 14px;
}
